.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.label {
  line-height: 14px;
  margin-right: 6px;
  white-space: nowrap;
  margin-bottom: 2px;
  color: #fff;
}
@media (max-width: 900px) {
  .wrap {
    padding: 0;
    display: grid;
    max-width: 420px;
    row-gap: 20px;
    margin: 0 auto;
  }
  .label {
    justify-self: end;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
