.wrapInput {
  display: flex;
  align-items: center;
  // margin-left: 30px;
}
.wrap {
  width: 360px;
  position: relative;
}
.input {
  font-family: inherit;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f3f0;
  padding: 5px 8px;
}
@media (max-width: 900px) {
  .wrap {
    width: 100%;
    margin: 0 auto;
    grid-column: 1/3;
    grid-row: 2/3;
    padding-bottom: 20px;
  }
  .input {
    width: 100%;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f5f3f0;
  position: absolute;
  top: 100%;
  width: 360px;
  left: 0;
  z-index: 2;
}
.notFound {
  width: 100% !important;
  color: #777;
  padding: 10px;
  text-align: center;
  background-color: #f5f3f0;
}
.menuLink {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  padding: 0;
  width: 360px;
  white-space: nowrap;
  padding: 6px;
  color: #777;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  &:hover {
    background-color: #80241e;
    color: white;
  }
  &:hover span {
    color: rgba(255, 255, 255, 0.7);
  }
  & span {
    display: inline-block;
    margin-right: 4px;
    color: rgba(102, 102, 102, 0.5);
  }
}
