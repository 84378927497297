.wrap {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
}
.left {
  margin-top: 14px;
  padding-left: 20px;
  & .info {
    margin-top: 4px;
  }
}
.infoLine {
  display: flex;
  align-items: center;
}
.info {
  display: flex;
  align-items: center;
}
.infoLabel {
  margin-right: 2px;
}
.infoValue {
  font-weight: 700;
}

.infoSocial {
  display: flex;
  align-items: center;
}

.infoSocialItem {
  &:nth-child(1) {
    width: 16px;
    height: 10px;
    margin-top: 1px;
    margin-right: 4px;
    color: black;
    background-color: #9bcadf;
    img {
      display: block;
      object-fit: contain;
      width: 16px;
      height: 10px;
    }
  }
  &:nth-child(2) {
    width: 12px;
    height: 12px;
    & img {
      display: block;
      object-fit: contain;
      width: 12px;
      height: 12px;
    }
  }
}
.logo {
  margin-left: auto;
  margin-right: auto;
}
.right {
  margin-top: 27px;
  padding-right: 20px;
  & .info {
    margin-top: 4px;
  }
  display: flex;
  flex-direction: column;
  align-items: end;
  align-self: flex-start;
}
@media (max-width: 900px) {
  .left,
  .right {
    display: none;
  }
  .logo {
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
