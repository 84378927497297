.wrap {
  background-color: #85c3c5;
  color: #fff;
  width: 350px;
  margin: 0 auto;
}
.content {
  border: 1px solid #fff;
  padding: 10px;
  margin: 10px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}
.subtitle {
  text-align: center;
  margin-bottom: 60px;
}
.centerText {
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.bottom {
  display: grid;
  align-items: end;
  grid-template-columns: 1fr 80px;
}
.bottomList {
  margin-bottom: 35px;
}
.bottomItem {
  //   display: inline-block;
  //   align-items: center;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  & a {
    color: #337ab7;
    text-decoration: none;
  }
  & + & {
    margin-top: 5px;
  }
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
    background-image: url(../../../../public/img/check.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    margin-right: 20px;
  }
}

.bottomText {
  line-height: 19px;
}
.bottomImg {
  width: 80px;
  height: 80px;
}
