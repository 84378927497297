.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: 50px;
  font-size: 20px;
}
@media (max-width: 900px) {
  .list {
    margin-top: 0px;
  }
}
.link {
    color: #000;
    text-decoration: none;
    padding: 4px;
    background-color: #ffffff;
    font-weight: 800;
  & + & {
    margin-left: 20px;
	font-size: 20px;
  }
}
