.wrapper {
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr 140px;
  grid-gap: 10px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapperNotLoaded {
  display: none !important;
}
.close {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #fff;
  top: 0px;
  right: 0px;
  padding: 0;
  position: fixed;
}
.mainWrap {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
}
.main {
  -webkit-user-drag: none;
  display: block;
  align-self: center;
  max-width: 80vw;
  margin: 0 auto;
  max-height: calc(100vh - 170px);
  object-fit: contain;
}
.prev {
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
.next {
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
.list {
  padding-bottom: 20px;
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  justify-content: center;
  grid-gap: 10px;
}
.firstImg {
  width: 150px;
  height: 180px;
  margin: 10px auto;
  object-fit: cover;
  display: block;
}
.wrapFirstImg {
  cursor: pointer;
  width: 150px;
  height: 180px;
  margin: 10px auto;
  position: relative;
  &:hover .wrapFirstImgIcon {
    opacity: 1;
  }
}
.wrapFirstImgIcon {
  transition: opacity 0.3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  opacity: 0;
}
.firstImgIcon {
}
.item {
  & img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    -webkit-user-drag: none;
  }
  position: relative;
  user-select: none;
  cursor: pointer;
  width: 100px;
  height: 100px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transition: opacity 0.3s;
  }
}
.itemActive {
  &::after {
    opacity: 0;
  }
  cursor: auto;
}
