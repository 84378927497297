.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
.active {
  opacity: 1;
  visibility: visible;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #fff;
  border: none;
}
.title {
}
.modal {
  padding: 40px 100px;
  max-width: 500px;
  background-color: #fff;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
