.wrap {
  background-color: #910a0a;
  color: #fff;
  width: 350px;
  margin: 0 auto;
}
.content {
  border: 1px solid #fff;
  padding: 10px;
  margin: 10px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}
.subtitle {
  text-align: center;
}
.centerText {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 50px;
}
.bottom {
  display: flex;
  align-items: end;
}
.bottomText {
  line-height: 19px;
}
.bottomImg {
  width: 80px;
  height: 80px;
}
