.title {
  margin-top: 0;
  margin-bottom: 50px;
}
.date {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.wrap {
  margin-top: 40px;
}
