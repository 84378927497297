@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Times New Roman', Times, serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
* {
  box-sizing: border-box;
}
.container {
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}
.btn {
  font-family: inherit;
  outline: none;
  color: white;
  background-color: #80241e;
  border-radius: 0;
  border: none;
  height: 25px;
  padding: 5px 12px 7px 12px;
  line-height: 11px;
  cursor: pointer;
  user-select: none;
}
.ql-editor {
  height: 400px !important;
}
.quill-table .ql-editor {
  height: 200px !important;
}

.input-table-service {
  width: calc(100% - 72px);
  margin-left: 16px;
  display: block;
  resize: none;
  height: 30.75px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-family: inherit;
  margin-top: 8px;
  margin-bottom: 8px;
  outline: none;
  border-radius: 4px;
  padding: 6px 12px;
}

.input-wrap {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  margin-top: 20px;
}
.input-label {
}
.input-label-required::before {
  content: '*';
  color: #ff1744;
  display: inline-block;
  margin-right: 3px;
}
.input-error {
  color: #ff1744;
}
.input-error .input-custom::placeholder {
  color: #ff1744;
}
.input-custom,
.textarea-custom {
  padding: 5px 10px;
  font-family: inherit;
  border: none;
  outline: none;
  background-color: #f5f3f0;
}
.textarea-custom {
  resize: none;
}
.textarea-wrap {
  align-items: start;
}
.textarea-label {
  margin-top: 3px;
}
.checkbox-custom {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.checkbox-error {
  color: #ff1744;
}
.checkbox-custom div {
  width: 12px;
  height: 12px;
  background-color: #e9e7e3;
  margin-right: 5px;
}
.checkbox-custom input {
  display: none;
}
.checkbox-custom input:checked ~ div {
  background-color: #80241e;
}
.loading-text {
  width: min-content;
  white-space: nowrap;
  clip-path: inset(0 0.7em 0 0);
  animation: l 1s steps(4) infinite;
  line-height: 14px;
}

@keyframes l {
  to {
    clip-path: inset(0 -0.3em 0 0);
  }
}
.react-select-container {
  background-color: #f5f3f0;
  font-family: inherit;
  font-size: 14px;
  padding: 3px 10px;
  height: 26.8px;
}
.react-select__control {
  min-height: 10px !important;
}
.react-select__multi-value__label {
  margin-right: 1px;
}
.react-select__multi-value {
  margin-right: 5px;
}
.react-select__multi-value__remove path {
  fill: rgba(0, 0, 0, 0.5);
}
.react-select__multi-value__remove {
  margin-top: 2px;
  cursor: pointer;
}
.react-select__menu {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f3f0;
  margin-left: -10px;
  padding: 5px 0;
  /* padding: 5px 6px; */
}
.react-select__indicator path {
  fill: rgba(0, 0, 0, 0.5);
}
.react-select__option {
  padding: 5px 11px;
}
.react-select__option:hover {
  background-color: #80241e;
  color: #fff;
}
.react-select__option {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}
.react-select__group-heading {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 6px;
  padding-left: 5px;
  margin-right: 11px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.react-select-container-error .react-select__placeholder {
  color: #ff1744;
}
.input-error-select .input-lable {
  color: #ff1744;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
}

.btn-more {
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  background-color: #f5f3f0;
  padding: 10px;
  border: none;
  margin-top: 20px;
  border-radius: 4px;
}
#toggleHelper {
  background-color: #888;
}
#helperLibrary {
  top: 2%;
}

p {
margin-top: 0px;
margin-bottom: 0px;
}

em {

display: inline-block;
min-height: 14px;
min-width: 14px;
}

a {
color: blue !important;
}
