.wrap {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
}
.head {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  // padding-left: 6px;
  &::before {
    content: '';
    display: block;
  }
  font-weight: 700;
  margin-bottom: 16px;
}
.list {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 300px));
  justify-content: space-between;
  grid-gap: 30px;
}
.item {
  cursor: pointer;
  &:nth-child(1) {
    padding-left: 6px;
  }
  & + & {
    margin-top: 18px;
  }
}
.date {
  font-weight: 700;
  margin-bottom: 3px;
}
.name {
	color: blue !important;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: underline;
}
@media (max-width: 900px) {
  .list {
    grid-template-columns: 1fr;
  }
}
