.table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  margin-bottom: 70px;
  & th {
    background-color: #0e1b44;
    color: #fff;
    padding: 0;
    padding: 15px;
    font-size: 16px;
  }
  & tr:nth-child(even) {
    background: #f5f3f0;
  }
}

.tableBtnBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableBtn {
  cursor: pointer;
  &:first-child {
    margin-right: 5px;
  }
  border: none;
  font-family: inherit;
  color: #fff;
  padding: 4px 10px 5px;
  background-color: #6c8dd1;
}

.title {
  padding-bottom: 10px;
}
.serviceCell {
  padding: 10px 15px;
}
.groupCell {
  padding: 10px;
  color: #fff;
  text-align: center;
  background-color: #80241e;
}
