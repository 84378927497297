.tabList {
  display: grid;
  padding-bottom: 20px;
}
.tabWrapper {
  margin-bottom: 70px;
}
.tab {
  cursor: pointer;
  font-size: 16px;
  font-family: inherit;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 15px;
  position: relative;
  transition: all 0.3s;
  color: rgb(0 0 0);
  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 100%;
    bottom: -3px;
    left: 0;
    position: absolute;
    background-color: #80241e;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
}
.tabActive {
  text-shadow: 1px 0 0 #80241e;
  color: #80241e;
  &::after {
    opacity: 1;
    visibility: visible;
  }
}
.tabMapList {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.tabMapListItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;
  & + & {
    margin-top: 15px;
  }

  & div:first-child {
    font-weight: 700;
    color: #80241e;
    font-size: 16px;
  }
  & div:last-child {
    font-weight: 700;
    // opacity: 0.8;
  }
}
.tabMap {
  display: none;
  grid-gap: 30px;
  grid-template-columns: 600px 1fr;
}

.tabMapShow {
  display: grid !important;
}
