.wrap {
  margin: 0 auto;
  padding-top: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  height: 100%;
}
