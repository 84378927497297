.wrap {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 40px;
  justify-content: space-evenly;
  //   grid-template-columns: repeat(3, 350px);
  margin-bottom: 40px;
}
.fullBanner {
margin: 0 auto;
display: block;
margin-bottom: 30px;
}
